import ADDRESS from "@/settings";
import axios from "axios"
import {WalkingMapModel}  from "@/model/tool07_walkingMap";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class WalkingMapServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<WalkingMapModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/walkingmaps/paginate`, query);
      let docs: Array<WalkingMapModel> = response.data.docs.map((el: any) => new WalkingMapModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<WalkingMapModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<WalkingMapModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/walkingmap/get/${id}`);
      let doc: WalkingMapModel = new WalkingMapModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Structure: WalkingMapModel) {
    return new Promise<WalkingMapModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/walkingmap/get/${id}`, Structure );
      let doc: WalkingMapModel = new WalkingMapModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Structure: WalkingMapModel) {
    return new Promise<WalkingMapModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/walkingmap/create/`, Structure);
      let doc: WalkingMapModel = new WalkingMapModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<WalkingMapModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/walkingmap/delete/${id}`);
      let doc: WalkingMapModel = new WalkingMapModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
