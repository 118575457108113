import ADDRESS from "@/settings";
import axios from "axios"
import MindMapItemModel from "@/model/mindmapItem";
import MindMapModel from "@/model/mindmap"
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export class MindMapServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<MindMapModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/mindmaps/paginate`, query);
      let docs: Array<MindMapModel> = response.data.docs.map((el: any) => new MindMapModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<MindMapModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<MindMapModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/mindmap/get/${id}`);
      let doc: MindMapModel = new MindMapModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, item: MindMapModel) {
    return new Promise<MindMapModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/mindmap/update/${id}`, { doc: item });
      let doc: MindMapModel = new MindMapModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(item: MindMapItemModel) {
    return new Promise<MindMapItemModel>(async (resolve, reject) => {
      let prep: any = Object.assign({}, item)
      delete prep.isActive
      console.log({ prep })
      let response = await axios.post(`${ADDRESS}/mindmap/create/`, { doc: prep });
      let doc: MindMapItemModel = new MindMapItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<MindMapItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/mindmap/delete/${id}`);
      let doc: MindMapItemModel = new MindMapItemModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}


export class MindMapItemServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<MindMapItemModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/mindmapItems/paginate`, query);
      let docs: Array<MindMapItemModel> = response.data.docs.map((el: any) => new MindMapItemModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<MindMapItemModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<MindMapItemModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/mindmapItem/get/${id}`);
      let doc: MindMapItemModel = new MindMapItemModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, item: MindMapItemModel) {
    return new Promise<MindMapItemModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/mindmapItem/update/${id}`, { doc: item });
      let doc: MindMapItemModel = new MindMapItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(item: MindMapItemModel) {
    return new Promise<MindMapItemModel>(async (resolve, reject) => {
      let prep: any = Object.assign({}, item)
      delete prep.isActive
      console.log({ prep })
      let response = await axios.post(`${ADDRESS}/resourceItem/create/`, { doc: prep });
      let doc: MindMapItemModel = new MindMapItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<MindMapItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/mindmapItem/delete/${id}`);
      let doc: MindMapItemModel = new MindMapItemModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
