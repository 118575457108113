import { PaginateResponse } from "@/common";
import PersonHistoryModel, { PersonHistoryItemModel } from "@/model/tool07_personHistory";
import PersonHistoryServices, { PersonHistoryItemServices } from "@/services/tool07_personHistory"
import { Service } from 'typedi';

@Service()
export class PersonHistorySingleClass {
  data?: PersonHistoryModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new PersonHistoryServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new PersonHistoryServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new PersonHistoryServices().create(this.data!)
    this.loading = false
  }
  public init(project_id: string, community_id: string) {
    this.loading = true
    this.data = new PersonHistoryModel({ project_id: project_id, community_id: community_id })
    this.loading = false
  }
}

@Service()
export class  PersonHistoryListClass {
  data?: PaginateResponse<PersonHistoryModel>;
  loading: boolean = true;
  query:any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new PersonHistoryServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new PersonHistoryServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id:string) {
    this.loading = true
    let response = await new PersonHistoryServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}

@Service()
export class PersonHistoryItemSingleClass {
  data?: PersonHistoryItemModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new PersonHistoryItemServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new PersonHistoryItemServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new PersonHistoryItemServices().create(this.data!)
    this.loading = false
  }
  public init(person_history_id: string) {
    this.loading = true
    this.data = new PersonHistoryItemModel({ person_history_id: person_history_id })
    this.loading = false
  }
}

@Service()
export class  PersonHistoryItemListClass {
  data?: PaginateResponse<PersonHistoryItemModel>;
  loading: boolean = true;
  query:any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new PersonHistoryItemServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new PersonHistoryItemServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id:string) {
    this.loading = true
    let response = await new PersonHistoryItemServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}