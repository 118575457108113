import axios from "axios";
import ADDRESS from "@/settings"
import RelationModel from "@/model/relation";
import RelationItemModel from "@/model/relationItem"

export default class RelationServices {
  public async paginateRelationItem(query: any): Promise<Array<RelationModel>> {
    return new Promise((resolve, reject) => {
      axios.post(ADDRESS + "/family-members/paginate", query).then((response) => {
        let docs = response.data.docs
        docs = docs.map((item: any) => new RelationItemModel(item))
        resolve(docs)
      });
    })
  }
  public async addMember(member: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let prep = Object.assign({}, member)
      delete prep.id
      delete prep.isActive
      delete prep.isLeader
      axios.post(ADDRESS + "/relationItem/create", { doc: prep }).then((response) => {
        let doc = response.data.doc
        resolve(doc)
      });
    })
  }
  public async removeMember(member: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let prep = Object.assign({}, member)
      axios.delete(ADDRESS + "/relationItem/delete/" + prep.id).then((response) => {
        let docs = response.data.docs
        resolve("done")
      });
    })
  }
  public async updateMember(member: any): Promise<string> {
    return new Promise((resolve, reject) => {
      let prep = Object.assign({}, member)
      let id = member.id
      delete prep.id
      delete prep.isActive
      delete prep.isLeader
      axios.patch(ADDRESS + "/relationItem/update/" + id, prep).then((response) => {
        console.log("response", response)
        let docs = response.data.docs
        resolve("done")
      });
    })
  }
}