import ADDRESS from "@/settings";
import axios from "axios"
import CommunityHistoryModel from "@/model/tool07_communityHistory";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class CommunityHistoryServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<CommunityHistoryModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/histories/paginate`, query);
      let docs: Array<CommunityHistoryModel> = response.data.docs.map((el: any) => new CommunityHistoryModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<CommunityHistoryModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<CommunityHistoryModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/history/get/${id}`);
      let doc: CommunityHistoryModel = new CommunityHistoryModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, CommunityHistory: CommunityHistoryModel) {
    return new Promise<CommunityHistoryModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/history/update/${id}`, CommunityHistory );
      let doc: CommunityHistoryModel = new CommunityHistoryModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(CommunityHistory: CommunityHistoryModel) {
    return new Promise<CommunityHistoryModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/history/create/`, CommunityHistory);
      let doc: CommunityHistoryModel = new CommunityHistoryModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<CommunityHistoryModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/history/delete/${id}`);
      let doc: CommunityHistoryModel = new CommunityHistoryModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
