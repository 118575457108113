import { PaginateResponse } from "@/common";
import { CommunityModel } from "@/model/community";
import CommunityServices from "@/services/community";
import { Service } from 'typedi';
@Service()
export class CommunityListClass {
  data?: PaginateResponse<CommunityModel>;
  loading: boolean = true;
  query: any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new CommunityServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new CommunityServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id: string) {
    this.loading = true
    let response = await new CommunityServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}
@Service()
export class CommunitySingleClass {
  data?: CommunityModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new CommunityServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new CommunityServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create(id :string) {
    this.loading = true
    console.log(this.data);
    this.data!.project_id = id
    this.data = await new CommunityServices().create(this.data!)
    this.loading = false
  }
  public init() {
    this.loading = true
    this.data = new CommunityModel({})
    this.loading = false
  }
}