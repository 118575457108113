import { BaseModelClass } from "@/model/common"
import { DateTime } from "luxon";

export class CommunityModel extends BaseModelClass {
  seq!: number
  id?: string
  project_id: string
  officialName: string
  localName: string
  period: string
  history: string
  region: string
  province:string
  district:string
  subDistrict:string
  postalCode:string
  villageName:string
  address:string
  latitude:number
  longitude: number
  description: string
  createdIP: string
  createdAt: Date
  modifiedAt: Date
  constructor(jsonData: any) {
    super();
    this.seq = jsonData.seq
    this.id = jsonData.id
    this.project_id = jsonData.project_id
    this.officialName = jsonData.officialName
    this.localName = jsonData.localName
    this.period = jsonData.period
    this.history = jsonData.history
    this.region = jsonData.region
    this.province = jsonData.province
    this.district = jsonData.district
    this.subDistrict = jsonData.subDistrict
    this.postalCode = jsonData.postalCode
    this.villageName = jsonData.villageName
    this.address = jsonData.address
    this.latitude = jsonData.latitude
    this.longitude = jsonData.longitude
    this.description = jsonData.description
    this.createdIP = jsonData.createdIP
    this.createdAt = jsonData.createdAt
    this.modifiedAt = jsonData.modifiedAt
  }
  toJSON() {
    let obj = Object.assign({}, this)
    return obj
  }
}