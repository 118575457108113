import RelationItemModel from "../relationItem";

export default class Relation {
  id: string;
  name: string;
  items: Array<RelationItemModel>;
  constructor(obj: any) {
    this.id = obj.id
    this.name = obj.name
    this.items = (obj.items ?? []).map((item: any) => new RelationItemModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj._id
    delete obj._id
    return obj
  }
}