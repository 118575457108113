import { PaginateResponse } from "@/common";
import {MediaModel} from "@/model/media";
import MediaServices from "@/services/media"
import { Service } from 'typedi';

@Service()
export class  MediaSingleClass {
  data?: MediaModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new MediaServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new MediaServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new MediaServices().create(this.data!)
    this.loading = false
  }
  async createMany(){
    let list = this.data!.paths
    console.log({list})
    for(const item of list??[]){
      let prep:MediaModel = new MediaModel({...this.data, paths:undefined, path:item.path, size:item.size})
      console.log({item})
      console.log({prep})
      // prep.path = item
      let response = await new MediaServices().create(prep);
      console.log("done")
    }
  }
  async init(project_id:string, community_id:string){
    this.loading = true
    this.data = new MediaModel({project_id,community_id});
    this.loading = false
  }
}

@Service()
export class MediaListClass {
  data?: PaginateResponse<MediaModel>;
  loading: boolean = true;
  query:any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new MediaServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new MediaServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id:string) {
    this.loading = true
    let response = await new MediaServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}