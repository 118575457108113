// import { plainToClass } from 'class-transformer';
import { BaseModelClass } from "@/model/common"
import { DateTime } from "luxon";

export default class PersonHistoryModel extends BaseModelClass {
  seq!: number
  id?: string
  nickName!: string
  firstName!: string
  lastName!: string
  gender!: string
  age!:number
  
  dateOfBirth!: Date
  description!: string
  person_history_id!: string
  // person_history    PersonHistory
  project_id!: string
  // project           Project
  community_id!: string
  // community         Community
  items!: Array<any>
  media!: Array<any>
  uuid!: string
  createdIP!: string
  createdAt!: Date
  modifiedAt!: Date
  constructor(doc: any) {
    super();
    this.seq = doc.seq
    this.id = doc.id
    this.nickName = doc.nickName
    this.firstName = doc.firstName
    this.lastName = doc.lastName
    this.gender = doc.gender
    this.age = doc.age
    this.dateOfBirth = new Date(doc.dateOfBirth)
    this.description = doc.description
    this.person_history_id = doc.person_history_id
    this.project_id = doc.project_id
    this.community_id = doc.community_id
    this.items = doc.items
    this.media = doc.media
    this.uuid = doc.uuid
    this.createdIP = doc.createdIP
    this.createdAt = doc.createdAt
    this.modifiedAt = doc.modifiedAt
  }
  getFormatDateTime() {
    let format = this.dateOfBirth.toLocaleDateString('th-TH', { year: 'numeric', month: 'short', day: 'numeric' })
    return format
  }
}
export  class PersonHistoryItemModel extends BaseModelClass {
  id?: string
  name: string
  description: string
  person_history_id: string
  day: number
  month: number
  year: number
  createdIP: string
  createdAt: Date
  modifiedAt: Date
  constructor(doc: any) {
    super();
    this.id = doc.id
    this.name = doc.name
    this.day = doc.day
    this.month = doc.month
    this.year = doc.year
    this.description = doc.description
    this.person_history_id = doc.person_history_id
    this.createdIP = doc.createdIP
    this.createdAt = doc.createdAt
    this.modifiedAt = doc.modifiedAt
  }
}
export  class PersonHistoryMediaModel extends BaseModelClass {
  seq!: number
  id?: string
  person_history_id: string
  media_id!: string

  constructor(doc: any) {
    super();
    this.seq = doc.seq
    this.id = doc.id
    this.person_history_id = doc.person_history_id
    this.media_id = doc.media_id
  }
}