
import { PaginateResponse } from '@/common';
import { WalkingMapModel } from '@/model/tool07_walkingMap';
import WalkingMapServices from '@/services/tool07_walkingMap';
import { Service } from 'typedi';
import { useToast, POSITION } from "vue-toastification";
@Service()
export class WalkingMapSingleClass {
  data?: WalkingMapModel;
  loading: boolean = true;
  // async get(id: string) {
  //   this.loading = true
  //   this.data = await new WalkingMapServices().get(id)
  //   this.loading = false
  // }
  async update() {
    this.loading = true
    this.data = await new WalkingMapServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    await new WalkingMapServices().create(this.data!)
    const toast = useToast();
    toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
      timeout: 2000,
      position: POSITION.BOTTOM_RIGHT,
    });
    this.loading = false
  }
}
@Service()
export class WalkingMapListClass {
  data?: PaginateResponse<WalkingMapModel>;
  loading: boolean = true;
  query:any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new WalkingMapServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new WalkingMapServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id:string) {
    this.loading = true
    let response = await new WalkingMapServices().delete(id)
    this.refreshList()
    this.loading = false
  }

}