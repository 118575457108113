import { PaginateResponse } from "@/common";
import ProjectModel from "@/model/project";
import ProjectServices from "@/services/project"
import { Service } from 'typedi';
import { useToast, POSITION } from "vue-toastification";

const toast = useToast();
@Service()
export class ProjectListClass {
  data?: PaginateResponse<ProjectModel>;
  loading: boolean = true;
  query: any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new ProjectServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new ProjectServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id: string) {
    this.loading = true
    let response = await new ProjectServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}

@Service()
export class ProjectSingleClass {
  data?: ProjectModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new ProjectServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new ProjectServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create(email:string) {
    this.loading = true
    this.data = await new ProjectServices().create(this.data!,email)
    this.loading = false
  }
  public init() {
    this.loading = true
    this.data = new ProjectModel({})
    this.loading = false
  }
}