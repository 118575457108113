// import { plainToClass } from 'class-transformer';
import { BaseModelClass } from "@/model/common"
import { DateTime } from "luxon";

export default class CommunityHistoryModel extends BaseModelClass {
  seq!: number
  id!: string
  name!: string
  description!: string
  type!: string
  startDate!: Date
  endDate!: Date
  startObj!:any
  endObj!: any
  project_id!: string
  community_id!: string
  startDay:number
  startMonth:number
  startYear:number
  endDay:number
  endMonth:number
  endYear:number
  uuid!: string
  createdIP!: string
  createdAt!: Date
  modifiedAt!: Date
  constructor(doc: any) {
    super();
    this.seq = doc.seq
    this.id = doc.id
    this.name = doc.name
    this.description = doc.description
    this.type = doc.type
    this.startDate = new Date(doc.startDate)
    this.endDate = new Date(doc.startDate)
    this.startObj = doc.startObj
    this.endObj = doc.endObj
    this.project_id = doc.project_id
    this.community_id = doc.community_id
    this.uuid = doc.uuid
    this.createdIP = doc.createdIP
    this.createdAt = doc.createdAt
    this.modifiedAt = doc.modifiedAt
    this.startDay = doc.startDay
    this.startMonth = doc.startMonth
    this.startYear = doc.startYear
    this.endDay = doc.endDay
    this.endMonth = doc.endMonth
    this.endYear = doc.endYear
  }
}
class CommunityHistoryObjModel {

}
class CommunityHistoryStartObjModel {
  year:number
  month:number
  day:number
  constructor(doc: any) {
    
    this.year = doc.year
    this.month = doc.month
    this.day = doc.day
}
}
class CommunityHistoryEndObjModel {
  year:number
  month:number
  day:number
  constructor(doc: any) {
    
    this.year = doc.year??0
    this.month = doc.month??0
    this.day = doc.day??0
}

}
