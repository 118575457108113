import ADDRESS from "@/settings";
import axios from "axios"
import UserModel from "@/model/user";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";

export default class UserServices {
  public login(username: string, password:string) {
    let query = {
      "username": username,
      "password": password
    }
    return new Promise<UserModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/user/login`, query);
      if (response.data.status != 'success') {
        reject("error")
      } else {
        let doc: UserModel = new UserModel(response.data)
        console.log("resolving")
        resolve(doc)
      }
    })
  }
}