import ADDRESS from "@/settings";
import axios from "axios"
import { MediaModel } from "@/model/media";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class MediaServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<MediaModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/medias/paginate`, query);
      let docs: Array<MediaModel> = response.data.docs.map((el: any) => new MediaModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<MediaModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<MediaModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/media/get/${id}`);
      let doc: MediaModel = new MediaModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Media: MediaModel) {
    return new Promise<MediaModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/media/update/${id}`, Media.toJSON());
      let doc: MediaModel = new MediaModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Media: MediaModel) {
    return new Promise<MediaModel>(async (resolve, reject) => {
      delete Media.paths
      let response = await axios.post(`${ADDRESS}/media/create/`, Media.toJSON());
      let doc: MediaModel = new MediaModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<MediaModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/media/delete/${id}`);
      let doc: MediaModel = new MediaModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
