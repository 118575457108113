import { PaginateResponse } from "@/common";
import CalendarModel from "@/model/tool07_calendar";
import CalendarServices from "@/services/tool07_calendar"
import { Service } from 'typedi';

interface monthData {
  text: string;
  n: number;
}
@Service()
export class CalendarSingleClass {
  data?: CalendarModel;
  loading: boolean = true;
  pushMonth(obj: monthData) {
    if (this.data!.months === undefined) this.data!.months = []
    let foundIndex = this.data!.months.findIndex(m => m === obj.n)
    if (foundIndex != -1) {
      this.data!.months.splice(foundIndex, 1)
    } else {
      this.data?.months.push(obj.n)
    }
  }
  async get(id: string) {
    this.loading = true
    this.data = await new CalendarServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new CalendarServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new CalendarServices().create(this.data!)
    this.loading = false
  }
  public init(project_id: string, community_id: string) {
    this.loading = true
    this.data = new CalendarModel({ project_id: project_id, community_id: community_id })
    this.loading = false
  }
}

@Service()
export class CalendarListClass {
  data?: PaginateResponse<CalendarModel>;
  loading: boolean = true;
  query: any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new CalendarServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new CalendarServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id: string) {
    this.loading = true
    let response = await new CalendarServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}