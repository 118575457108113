import ADDRESS from "@/settings";
import axios from "axios"
import StructureModel, { StructureItemModel, StructureItemRelationModel } from "@/model/tool07_structure";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class StructureServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<StructureModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/structures/paginate`, query);
      let docs: Array<StructureModel> = response.data.docs.map((el: any) => new StructureModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<StructureModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<StructureModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/structure/get/${id}`);
      let doc: StructureModel = new StructureModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Structure: StructureModel) {
    return new Promise<StructureModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/structure/get/${id}`, Structure);
      let doc: StructureModel = new StructureModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Structure: StructureModel) {
    return new Promise<StructureModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/structure/create/`, Structure);
      let doc: StructureModel = new StructureModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<StructureModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/structure/delete/${id}`);
      let doc: StructureModel = new StructureModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
  public async getStructureItemList(query: any): Promise<Array<StructureItemModel>> {
    return new Promise((resolve, reject) => {
      axios.post(ADDRESS + "/persons/paginate", query).then((response) => {
        let docs = response.data.docs
        docs = docs.map((item: any) => new StructureItemModel(item))
        resolve(docs)
      });
    })
  }
  public createStructureItem(StructureItem: StructureItemModel) {
    return new Promise<StructureItemModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/structureItem/create/`, StructureItem);
      let doc: StructureItemModel = new StructureItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}


export class StructureItemServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<StructureItemModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/persons/paginate`, query);
      let docs: Array<StructureItemModel> = response.data.docs.map((el: any) => new StructureItemModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<StructureItemModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<StructureItemModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/structureItem/get/${id}`);
      let doc: StructureItemModel = new StructureItemModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Structure: StructureItemModel) {
    return new Promise<StructureItemModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/structureItem/get/${id}`, Structure);
      let doc: StructureItemModel = new StructureItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Structure: StructureItemModel) {
    return new Promise<StructureItemModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/structureItem/create/`, Structure);
      let doc: StructureItemModel = new StructureItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<StructureItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/structureItem/delete/${id}`);
      let doc: StructureItemModel = new StructureItemModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}

export class StructureItemRelationServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<StructureItemRelationModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/structureItemRelations/paginate`, query);
      let docs: Array<StructureItemRelationModel> = response.data.docs.map((el: any) => new StructureItemRelationModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<StructureItemRelationModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<StructureItemRelationModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/structureItemRelation/get/${id}`);
      let doc: StructureItemRelationModel = new StructureItemRelationModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Structure: StructureItemRelationModel) {
    return new Promise<StructureItemRelationModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/structureItemRelation/update/${id}`, { doc: Structure });
      let doc: StructureItemRelationModel = new StructureItemRelationModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Structure: StructureItemRelationModel) {
    return new Promise<StructureItemRelationModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/structureItemRelation/create/`, { doc: Structure });
      let doc: StructureItemRelationModel = new StructureItemRelationModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<StructureItemRelationModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/structureItemRelation/delete/${id}`);
      let doc: StructureItemRelationModel = new StructureItemRelationModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
