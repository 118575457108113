import { BaseModelClass } from "@/model/common"
import { DateTime } from "luxon";

export default class ProjectModel extends BaseModelClass {
  seq!: number
  id?: string
  name!: string
  period: string
  region: string
  description!: string
  createdIP!: string
  createdAt!: Date
  modifiedAt!: Date
  usage:any
  items:Array<string>;
  // email:string
  // role:string
  constructor(doc: any) {
    super();
    this.seq = doc.seq
    this.id = doc.id
    this.name = doc.name
    this.period = doc.period
    this.region = doc.region
    this.description = doc.description
    this.createdIP = doc.createdIP
    this.createdAt = doc.createdAt
    this.modifiedAt = doc.modifiedAt
    this.items = doc.items
    this.usage = doc.usage
    // this.email = doc.email
    // this.role = doc.role
  }
  toJSON() {
    let obj = Object.assign({}, this)
    return obj
  }
}