import ADDRESS from "@/settings";
import axios from "axios"
import RelationModel from "@/model/tool07_relation";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";
import RelationItemModel from "@/model/relationItem";


export default class RelationServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<RelationModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/families/paginate`, query);
      let docs: Array<RelationModel> = response.data.docs.map((el: any) => new RelationModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<RelationModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<RelationModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/family/get/${id}`);
      let doc: RelationModel = new RelationModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Relation: RelationModel) {
    return new Promise<RelationModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/family/get/${id}`, Relation);
      let doc: RelationModel = new RelationModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(relation: RelationModel, relationItem: RelationItemModel) {
    return new Promise<RelationModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/family/create/`, {
        relation: {
          name: relationItem.firstName,
          project_id: relation.project_id,
          community_id: relation.community_id,
        }, firstMember: relationItem
      });
      let doc: RelationModel = new RelationModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<RelationModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/family/delete/${id}`);
      let doc: RelationModel = new RelationModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
