import ADDRESS from "@/settings";
import axios from "axios"
import CalendarModel from "@/model/tool07_calendar";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class CalendarServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<CalendarModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/calendars/paginate`, query);
      let docs: Array<CalendarModel> = response.data.docs.map((el: any) => new CalendarModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<CalendarModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<CalendarModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/calendar/get/${id}`);
      let doc: CalendarModel = new CalendarModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Calendar: CalendarModel) {
    return new Promise<CalendarModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/calendar/update/${id}`, Calendar );
      let doc: CalendarModel = new CalendarModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Calendar: CalendarModel) {
    return new Promise<CalendarModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/calendar/create/`, Calendar);
      let doc: CalendarModel = new CalendarModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<CalendarModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/calendar/delete/${id}`);
      let doc: CalendarModel = new CalendarModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
