import StructureModel, { StructureItemModel, StructureItemRelationModel } from "@/model/structure";
import StructureServices from "@/services/structure";
import { Service } from 'typedi';

@Service()
export class StructureRelationClass {
  data?: Array<StructureModel>;
  nodes?: Array<StructureItemModel>
  links?: Array<StructureItemRelationModel>
  loading: boolean = false;
  get loadingDone() {
    return this.data != undefined && this.nodes != undefined && this.links != undefined
  }
  getStructureGraph(community_id: string) {
    return new Promise(async (resolve, reject) => {
      let results = await new StructureServices().getStructureGraph(community_id)
      resolve(results)
      this.nodes = results.nodes
      this.links = results.links
    })
  }
  getStructure(community_id: string) {
    return new Promise(async (resolve, reject) => {
      let results = await new StructureServices().getStructureList({})
      console.log({results})
      // this.data = results
      resolve(results)
    })
  }
  getItems() {
    return new Promise(async (resolve, reject) => {
      let results = await new StructureServices().getStructureItemList({})
      this.nodes = results
      resolve(results)
    })
  }
  getLinks() {
    return new Promise(async (resolve, reject) => {
      let results = await new StructureServices().getStructureItemRelationList({})
      this.links = results
      resolve(results)
    })
  }

}