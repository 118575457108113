//let ADDRESS = "https://7tools.primrose-tech.com/api/v1"
//  let ADDRESS = "https://sac221.primrose-tech.com/api/v1"
// let ADDRESS = "http://localhost:8443/api/v1"
// let ADDRESS = "http://192.168.68.122:8443/api/v1" // ATS 
// let ADDRESS = "http://192.168.68.105:8443/api/v1" // JTM
// let ADDRESS = "http://192.168.1.31:8443/api/v1" //GoodHands
let ADDRESS = "https://7tools.sac.or.th/api/v1"; //SAC
// let ADDRESS = "http://192.168.177.247:8443/api/v1";
export const APIKEY = "2f66df29a833f9c87023ee0bb728531d"
export let filePath = ADDRESS.replace("api/v1", "api/v1");
export default ADDRESS