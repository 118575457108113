import { PaginateResponse } from "@/common";
import CommunityHistoryModel from "@/model/tool07_communityHistory";
import CommunityHistoryServices from "@/services/tool07_communityHistory"
import { Service } from 'typedi';

@Service()
export class  CommunityHistorySingleClass {
  data?: CommunityHistoryModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new CommunityHistoryServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new CommunityHistoryServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new CommunityHistoryServices().create(this.data!)
    this.loading = false
  }
  public init(project_id: string, community_id: string) {
    this.loading = true
    this.data = new CommunityHistoryModel({ project_id: project_id, community_id: community_id })
    this.loading = false
  }
}

@Service()
export class CommunityHistoryListClass {
  data?: PaginateResponse<CommunityHistoryModel>;
  loading: boolean = true;
  query:any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new CommunityHistoryServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new CommunityHistoryServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id:string) {
    this.loading = true
    let response = await new CommunityHistoryServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}