import { BaseModelClass } from "@/model/common";
import { DateTime } from "luxon";

export default class UserModel extends BaseModelClass {
  seq?: number;
  id?: string;
  sac_id: string;
  firstname: string;
  lastname: string;
  employee_id: string;
  email: string;
  position: string;
  department: string;
  user_type: string;
  phone: string;
  mobile: string;
  address: string;
  districts_id: string;
  districts_name: string;
  city: string;
  city_name: string;
  province: string;
  province_name: string;
  post_code: string;
  country: string;
  country_name: string;
  birthday: string;
  gender: string;
  ocupation: string;
  occupation_name: string;
  idcard: string;
  register_from: string;
  loginType: string;
  tokensSingleSignOn: string;
  isAcceptTerm: string;

  constructor(doc: any) {
    super();
    this.seq = doc.seq
    this.id = doc.id
    this.sac_id =  doc.sac_id
    this.firstname = doc.firstname
    this.lastname = doc.lastname
    this.employee_id = doc.employee_id
    this.email = doc.email
    this.position = doc.position
    this.department = doc.department
    this.user_type = doc.user_type
    this.phone = doc.phone
    this.mobile = doc.mobile
    this.address = doc.address
    this.districts_id = doc.districts_id
    this.districts_name = doc.districts_name
    this.city = doc.city
    this.city_name = doc.city_name
    this.province = doc.province
    this.province_name = doc.province_name
    this.post_code = doc.post_code
    this.country = doc.country
    this.country_name = doc.country_name
    this.birthday = doc.birthday
    this.gender = doc.gender 
    this.ocupation = doc.ocupation
    this.occupation_name = doc.occupation_name
    this.idcard = doc.idcard
    this.register_from = doc.register_from
    this.loginType = doc.loginType
    this.tokensSingleSignOn = doc.tokensSingleSignOn
    this.isAcceptTerm = doc.isAcceptTerm
  }
  toJSON() {
    let obj = Object.assign({}, this)
    return obj
  }
}
