import MindMapItemModel from "../mindmapItem";

export default class MindMap {
  id: string;
  name: string;
  items: Array<MindMapItemModel>;
  constructor(obj: any) {
    this.id = obj.id
    this.name = obj.name
    this.items = (obj.items ?? []).map((item: any) => new MindMapItemModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj.id
    return obj
  }
}