import ADDRESS from "@/settings";
import axios from "axios"
import ProjectModel from "@/model/project";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class ProjectServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<ProjectModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/projects/paginate`, query);
      let docs: Array<ProjectModel> = response.data.docs.map((el: any) => new ProjectModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<ProjectModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/project/get/${id}`);
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Project: ProjectModel) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/project/update/${id}`, Project.toJSON());
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Project: ProjectModel,email:string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/project/create/`, {doc:Project.toJSON(),email:email});
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<ProjectModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/project/delete/${id}`);
      let doc: ProjectModel = new ProjectModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
