import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'


export default createStore({
  state: {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    user_id: "",
    sac_id: "",
    token: "",
    jwt: "",
    role: "",
  },
  getters: {
  },
  mutations: {
    setUser(state, data) {
      state.username = data.username
      state.firstName = data.firstname
      state.lastName = data.lastname
      state.email = data.email
      state.user_id = data._id
      state.sac_id = data.sac_id
      state.token = data.token
      state.jwt = data.jwt
      state.role = data.role
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        key: "SAC221WF1",
        paths: ["username", "firstName", "lastName", "user_id", "sac_id", "role", "jwt", "email"]
      }
    )
  ]
})
