import { PaginateResponse } from "@/common";
import UserModel from "@/model/user";
import UserServices from "@/services/user"
import { Service } from 'typedi';
import router from "@/router"
import { result } from "lodash";
import { useToast, POSITION } from "vue-toastification";
import store from "@/store"
const toast = useToast();
@Service()
export class ActiveUserClass {
  data?: UserModel;
  loading: boolean = true;
  async login(username: string, password:string) {
    this.loading = true
    let result = await new UserServices().login(username, password).then(result => {
      // this.data = result as UserModel 
      store.commit("setUser",result)
      console.log(result)
      router.push("/portal/project")
    })
    .catch(error => {
      console.log(error)
      alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบ")
      // toast.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบ", {
      //   timeout: 2000,
      //   position: POSITION.BOTTOM_RIGHT,
      // });
    })
    this.loading = false
  }
}