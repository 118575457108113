import 'reflect-metadata';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import "../node_modules/bootstrap/dist/js/bootstrap";
import router from './router'
import store from './store'
import axios from "axios"

import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "../node_modules/leaflet/dist/leaflet.css";
import '../node_modules/quill/dist/quill.snow.css';
import './scss/app.scss';
import 'aos/dist/aos.css'
import AOS from "aos"

import PRUploadImages from "@/components/PRUploadImages.vue";
import PRCalendar from "@/components/PRCalendar.vue";
import PRUser from "@/components/PRUser.vue";
import PRRichText from "@/components/PRRichText.vue";
import PRMap from "@/components/PRMap.vue";
import D3Network from "vue-d3-network";
import PRAutoPerson from "@/components/PRAutoPerson.vue";
import LoadingWidget from "@/components/LoadingWidget.vue";

AOS.init()

axios.interceptors.request.use((config) => {
    const APIKEY = "2f66df29a833f9c87023ee0bb728531d"
    const token = APIKEY;
    config!.headers!.apikey = token;
    config!.headers!.token = token;
    // config!.headers!.authorization = `Bearer ${this.$store.state.jwt}`;
  
    return config;
  });
let app = createApp(App).use(Toast).use(store).use(router)

app.component('D3Network', D3Network)
app.component('PRUploadImages', PRUploadImages)
app.component('PRUser', PRUser)
app.component('PRAutoPerson', PRAutoPerson)
app.component('PRMap', PRMap)
app.component('PRRichText', PRRichText)
app.component('PRCalendar', PRCalendar)
app.component("LoadingWidget", LoadingWidget);

app.mount('#app')
