
export class PaginateResponse<T> {
  docs: Array<T> = [];
  pages: number = 0;
  total: number = 0;
  constructor(docs: Array<T>, pages: number, total: number) {
    this.docs = docs
    this.pages = pages
    this.total = total
  }
}
