export default class MindMapItemModel {
  id: string;
  name: string;
  description: string;
  depend_id?: string;
  mindMap_id: string;
  isActive = false;
  constructor(obj: any) {
    this.id = obj.id
    this.name = obj.name
    this.description = obj.description
    this.depend_id = obj.depend_id
    this.mindMap_id = obj.mindMap_id
  }
  toggleActive() {
    this.isActive = !this.isActive
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    // obj.id = obj._id
    // delete obj._id
    return obj
  }
}