import axios from "axios";
import ADDRESS from "@/settings"
import StructureModel, { StructureItemModel, StructureItemRelationModel } from "@/model/structure";

export default class StructureServices {
  // graph/structure/
  public async getStructureGraph(id:string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.get(ADDRESS + "/graph/structure/" + id).then((response) => {
        let data = response.data
        console.log({data})
        // docs = docs.map((item:any)=>new StructureModel(item))
        resolve(data)
      });
    })
  }
  public async getStructureList(query:any): Promise<Array<StructureModel>> {
    return new Promise((resolve, reject) => {
      axios.post(ADDRESS + "/structures/paginate", query).then((response) => {
        let docs = response.data.docs
        docs = docs.map((item:any)=>new StructureModel(item))
        resolve(docs)
      });
    })
  }
  public async getStructureItemList(query:any): Promise<Array<StructureItemModel>> {
    return new Promise((resolve, reject) => {
      axios.post(ADDRESS + "/persons/paginate", query).then((response) => {
        let docs = response.data.docs
        docs = docs.map((item:any)=>new StructureItemModel(item))
        resolve(docs)
      });
    })
  }
  public async getStructureItemRelationList(query:any): Promise<Array<StructureItemRelationModel>> {
    return new Promise((resolve, reject) => {
      axios.post(ADDRESS + "/structureItemRelations/paginate", query).then((response) => {
        let docs = response.data.docs
        docs = docs.map((item:any)=>new StructureItemRelationModel(item))
        resolve(docs)
      });
    })
  }
}