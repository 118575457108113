import ADDRESS from "@/settings";
import axios from "axios"
import { CommunityModel } from "@/model/community";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class CommunityServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<CommunityModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/communities/paginate`, query);
      let docs: Array<CommunityModel> = response.data.docs.map((el: any) => new CommunityModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<CommunityModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<CommunityModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/community/get/${id}`);
      let doc: CommunityModel = new CommunityModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Community: CommunityModel) {
    return new Promise<CommunityModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/community/update/${id}`, Community.toJSON());
      let doc: CommunityModel = new CommunityModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Community: CommunityModel) {
    return new Promise<CommunityModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/community/create/`, Community.toJSON());
      let doc: CommunityModel = new CommunityModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<CommunityModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/community/delete/${id}`);
      let doc: CommunityModel = new CommunityModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
