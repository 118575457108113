import axios from "axios";
import ADDRESS from "@/settings"
import { KnowledgeModel } from "@/model/knowledge";

export default class KnowledgeServices {
  public async getKnowledgeList(): Promise<Array<KnowledgeModel>> {
    return new Promise((resolve, reject) => {
      axios.get(ADDRESS + "/knowledges/list").then((response) => {
        let docs = response.data.docs
        console.log({docs})
        resolve(docs)
      });
    })
  }
}