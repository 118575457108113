export default class StructureModel {
  id?: string;
  name: string;
  description: string;
  type: string;
  project_id: string;
  community_id: string;
  items: Array<StructureItemModel>;
  constructor(obj: any) {
    this.description = obj.description
    this.type = obj.type
    this.project_id = obj.project_id
    this.community_id = obj.community_id
    this.id = obj.id
    this.name = obj.name
    this.items = (obj.items ?? []).map((item: any) => new StructureItemModel(item))
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj._id
    delete obj._id
    return obj
  }
}

export class StructureItemModel {
  id: string;
  nickName: string;
  firstName: string;
  lastName: string;
  middleName: string
  gender: string;
  group?: number
  age: number;
  isLeader: boolean;
  description: string;
  status: string;
  structure_id: string;
  project_id: string;
  community_id: string;
  targets: Array<StructureItemRelationModel>
  sources: Array<StructureItemRelationModel>
  constructor(jsonData: any) {
    console.log({ jsonData })
    this.id = jsonData.id
    this.nickName = jsonData.nickName
    this.firstName = jsonData.firstName
    this.middleName = jsonData.middleName
    this.lastName = jsonData.lastName
    this.gender = jsonData.gender
    this.age = jsonData.age
    this.group = jsonData.group
    this.isLeader = jsonData.isLeader ?? false
    this.description = jsonData.description
    this.status = jsonData.status
    this.structure_id = jsonData.structure_id
    this.project_id = jsonData.project_id
    this.community_id = jsonData.community_id
    this.targets = jsonData.targets??[]
    this.sources = jsonData.sources??[]
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj._id
    delete obj._id
    return obj
  }
}

export class StructureItemRelationModel {
  id: string;
  source_id: string;
  target_id: string;
  target: StructureItemModel;
  source: StructureItemModel;
  type: string;
  level: string;
  constructor(jsonData: any) {
    this.id = jsonData.id
    this.source_id = jsonData.source_id
    this.target_id = jsonData.target_id
    this.source = jsonData.source
    this.target = jsonData.target
    this.type = jsonData.type
    this.level = jsonData.level
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    return obj
  }
}