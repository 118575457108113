export class WalkingMapModel {
  id?: string;
  name: string;
  latitude: number;
  longitude: number;
  project_id?: string
  community_id?: string
  constructor(jsonData: any) {
    console.log({jsonData})
    this.id = jsonData.id
    this.name = jsonData.name ?? "-"
    this.project_id = jsonData.project_id
    this.community_id = jsonData.community_id
    this.latitude = 0 
    this.longitude = 0
    try {
      this.latitude = parseFloat(jsonData.latitude)
      this.longitude = parseFloat(jsonData.longitude)
    } catch (error) {
      
    }
  }
}

export class WalkingMapLinkModel {

}

export class WalkingMapTrackModel {

}