// import { plainToClass } from 'class-transformer';
import { BaseModelClass } from "@/model/common"
import { DateTime } from "luxon";

export default class CalendarModel extends BaseModelClass {
  seq!: number
  id?: string
  name!: string
  description!: string
  type!: string
  months!: Array<number>
  project_id!: string
  community_id!: string
  uuid!: string
  createdIP!: string
  createdAt!: Date
  modifiedAt!: Date
  constructor(doc: any) {
    super();
    this.seq = doc.seq
    this.id = doc.id
    this.name = doc.name
    this.description = doc.description
    this.type = doc.type
    this.months = doc.months
    this.project_id = doc.project_id
    this.community_id = doc.community_id
    this.uuid = doc.uuid
    this.createdIP = doc.createdIP
    this.createdAt = doc.createdAt
    this.modifiedAt = doc.modifiedAt
  }
}