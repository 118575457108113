import RelationModel from "@/model/relation";
import RelationItemModel from "@/model/relationItem";
import RelationServices from "@/services/relation";
import { Service } from 'typedi';
import { v4 as uuidv4 } from "uuid";


@Service()
export class ActiveRelationClass {
  data?: RelationModel;
  loading: boolean = false;
  maxTrial = 2
  svg: any
  activeItem?: RelationItemModel

  constructor(jsonData: Array<any>) {
    if (jsonData === undefined || jsonData.length === 0) {
      this.generateDummyData()
    }
    // this.generateDummyData()
  }
  
  async updateItem(member: any) {
    let result = await new RelationServices().updateMember(member)
  }

  async getRelationItems(id: string) {
    let results = await new RelationServices().paginateRelationItem({ search: {  } })
    
    this.data = new RelationModel({})
    this.data!.items = results.map(item => new RelationItemModel({...item, depend_id:undefined}))
    console.log("items", this.data!.items)
  }

  async removeItem(active: any) {
    let foundIndex = this.data?.items.findIndex(item => item.id === active.id)!
    let results = await new RelationServices().removeMember(active)
    this.data!.items.splice(foundIndex, 1)
  }

  addSibling(item: any) {
    let foundParent = this.data?.items.find(item => item.id === this.activeItem?.depend_id)
    let newItem = new RelationItemModel({ age: item.age, firstName: item.firstName, lastName: item.lastName, gender: item.gender, nickName: item.nickName, depend_id: foundParent!.id, parent: "normal", id: uuidv4() })
    let result = new RelationServices().addMember(newItem)
    this.data!.items.push(newItem)
  }

  async addParent(item: any, type: string = "normal") {
    let foundChild = this.data?.items.find(item => item.id === this.activeItem?.id)
    console.log({activeItem:this.activeItem})
    let newItem = new RelationItemModel({ age: item.age, firstName: item.firstName, lastName: item.lastName, gender: item.gender, nickName: item.nickName, relation_id: this.activeItem?.relation_id },)
    foundChild!.depend_id = newItem.id
    foundChild!.parent = type
    let result = await new RelationServices().addMember(newItem)
    let updateResult = new RelationServices().updateMember({id:foundChild?.id, depend_id: result.id, parent: foundChild?.parent})
    this.data!.items.push(newItem)
  }

  addChild(item: any, type: string = "normal") {
    let newItem = new RelationItemModel({ parent: type, age: item.age, firstName: item.firstName, lastName: item.lastName, gender: item.gender, nickName: item.nickName, depend_id: this.activeItem!.id },)
    let result = new RelationServices().addMember(newItem)
    this.data!.items.push(newItem)
  }

  addPartner(item: any) {
    let newItem = new RelationItemModel({ age: item.age, firstName: item.firstName, lastName: item.lastName, gender: item.gender, nickName: item.nickName, partner_id: this.activeItem!.id, id: uuidv4() },)
    let result = new RelationServices().addMember(newItem)
    this.data!.items.push(newItem)
  }

  generateDummyData() {
    this.data = new RelationModel({})
    let arr = [
      { age: 60, firstName: "มะม่วง", lastName: "สมใจ", gender: "male", parent: "normal", name: "ม่วง", id: "00-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      // { age: 60, firstName: "มันแกว", lastName: "สมใจ", gender: "male", parent: "normal", name: "ม่วง", id: "00-0X", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 70, firstName: "มะพร้าว", lastName: "สมใจ", gender: "female", parent: "normal", name: "พร้าว", id: "00-012", depend_id: "-", marriage: "normal", partner_id: "00-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 40, firstName: "ปราณี", lastName: "สมใจ", gender: "female", parent: "normal", name: "B", id: "01-01", depend_id: "00-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 50, firstName: "ประเสริฐ", lastName: "สมใจ", gender: "male", parent: "normal", name: "C", id: "02-01", depend_id: "01-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 45, firstName: "ภัสสร", lastName: "สมใจ", gender: "female", parent: "normal", name: "D", id: "03-01", depend_id: "01-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 52, firstName: "เวกัส", lastName: "สมใจ", gender: "nonBinary", parent: "normal", name: "E", id: "04-01", depend_id: "01-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 21, firstName: "เรนโบว์", lastName: "สมใจ", gender: "male", parent: "adopted", name: "F", id: "05-01", depend_id: "04-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 22, firstName: "สุกิจ", lastName: "สมใจ", gender: "female", parent: "normal", name: "G", id: "06-01", depend_id: "-", marriage: "normal", partner_id: "02-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 23, firstName: "สมบัติ", lastName: "สมใจ", gender: "male", parent: "normal", name: "H", id: "06-01", depend_id: "-", marriage: "conflict", partner_id: "01-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 48, firstName: "สมหมาย", lastName: "หวังสุข", gender: "male", parent: "normal", name: "หมาย", id: "07-01", depend_id: "-", marriage: "divorced", partner_id: "03-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" },
      { age: 48, firstName: "สุขใจ", lastName: "สรรค์", gender: "male", parent: "normal", name: "หมาย", id: "08-01", depend_id: "-", marriage: "normal", partner_id: "04-01", description: "ภัสสรเป็นผู้บริหารกิจการโรงแรมจิรานันตาสาขาพัทยา ซึ่งเป็นกิจการของครอบครัวที่ก่อตั้งโดยอากงสุกิจและอาม่าปราณี ปัจจุบันภัสสรแต่งงานกับนายวิเชียร์และมีบุตรชายด้วยกัน 4 คน" }
    ]
    this.data!.items = arr.map(item => new RelationItemModel(item))
  }
  clearIsActive() {
    this.data?.items.forEach((item => {
      item.isActive = false
    }))
  }
  findParentDirect(id: string) {
    try {
      let foundItem = this.data?.items.find(item => item.id === id)
      return this.data?.items.find(item => item.id === foundItem?.depend_id)
    } catch (error) {
      return null
    }
  }
  findParentInDirect(id: string) {
    try {
      let foundItem = this.findParentDirect(id)
      return this.data?.items.find(item => item.partner_id === foundItem?.id)
    } catch (error) {
      return null
    }
  }
  findSibling(id: string) {
    try {
      let foundItem = this.data?.items.find(item => item.id === id)
      return this.data!.items.filter(item => item.depend_id === foundItem?.depend_id && item.id !== foundItem!.id)
    } catch (error) {
      return []
    }
  }
  findPartner(id: string) {
    try {
      let foundItem = this.data?.items.find(item => item.id === id)
      return this.data!.items.find(item => item.partner_id === foundItem?.id)
    } catch (error) {
      return null
    }
  }
  async updateMarriageStatus(id: string, newStatus: string) {
    let result = await new RelationServices().updateMember({ marriage: newStatus, id: id })
    try {
      let foundItem = this.data?.items.find(item => item.id === id)
      foundItem!.marriage = newStatus
    } catch (error) {

    }
  }
  get tree() {
    let count = 0
    var nodes: Array<NodeModel> = [];
    var createNode = (elm: RelationItemModel): NodeModel => {
      var node = {
        id: elm.id,
        nickName: elm.nickName,
        firstName: elm.firstName,
        lastName: elm.lastName,
        gender: elm.gender,
        parent: elm.parent,
        marriage: elm.marriage,
        age: elm.age,
        partner: [this.data?.items.find(item => item.id === elm.id)],
        depend_id: elm.depend_id,
        relation_id: elm.relation_id,
        children: [],
      };
      let foundPartner = this.data?.items.find(item => item.partner_id === elm.id)
      if (foundPartner) node.partner.push(foundPartner)
      return node;
    };
    var fillElements = (node: NodeModel) => {
      node.children = this.data!.items.filter(item => item.depend_id === node.id).map(item => createNode(item))
    };
    var fillTree = (node: NodeModel) => {
      fillElements(node)
      if (node.children.length > 0) {
        node.children.forEach((n: NodeModel) => {
          fillElements(n)
          fillTree(n);
        });
      }
    };
    this.data!.items.forEach((elm, i) => {
      if (!elm.depend_id) {
        var root = createNode(elm);
        fillTree(root);
        nodes.push(root);
      }
    });
    return nodes;
  }
  get dataWithElapsed() {
    let data = this.data?.items.map(el => {
      return {
        ...el,
        elapsed: this.calculateTimeElapsed(el.id)
      }
    })
    data?.sort((a, b) => a.elapsed - b.elapsed)
    return data
  }
  calculateTimeElapsed(id: string) {
    let elapsed = 0
    try {
      let found = this.mapResult.find(item => item.original.search(id) != -1)
      let originalRoute = found?.originalRoute
      let route = found?.route
      let foundIndex = route!.findIndex(item => item === id)
      for (var i = 0; i < foundIndex; i++) {
        elapsed += parseInt(originalRoute![i].split("_")[1])
      }
    } catch (error) {

    }
    return elapsed
  }

  get mapResult() {
    let result = this.pathData.map((rs) => {
      let split = rs.split(",");
      let totalDay = 0;
      let path = "";
      let route: Array<string> = [];
      let originalRoute: Array<string> = [];
      try {
        totalDay = split
          .map((sp: string) => parseInt(sp.split("_")[1]))
          .reduce((a: number, b: number) => a + b, 0);
      } catch (error) { }
      try {
        path = split.map((sp: string) => sp.split("_")[0]).join("=>");
      } catch (error) { }
      try {
        route = split.map((sp: string) => sp.split("_")[0]);
        originalRoute = split.map((sp: string) => sp);
      } catch (error) { }
      return {
        original: rs,
        route,
        originalRoute,
        path,
        totalDay,
        end: route[route.length - 1],
        isMax: false,
      };
    });
    let max = Math.max(...result.map((rs) => rs.totalDay));
    result.forEach((rs) => {
      if (rs.totalDay === max) rs.isMax = true;
    });
    return result;
  }
  get pathData() {
    let result: Array<any> = [];
    let buildStrings = (parent: string, c: string) => {
      return this.data!.items.reduce((r: Array<any>, e: any) => {
        if (e.depend_id === parent) {
          var children = buildStrings(
            e.id,
            c + e.id + "_" + (e.duration ?? 0) + ","
          );
          if (!children.length) result.push(c + e.id + "_" + e.duration);
          r.push(e);
        }
        return r;
      }, []);
    }
    buildStrings("01-01", "");
    return result
  }
}


class NodeModel {
  id: string
  nickName: string
  firstName: string
  lastName: string
  age: number
  children: Array<NodeModel>
  depend_id?: string
  relation_id?: string
  constructor(obj: any) {
    this.id = obj.id
    this.nickName = obj.nickName
    this.firstName = obj.firstName
    this.lastName = obj.lastName
    this.age = obj.age
    this.children = obj.children
    this.depend_id = obj.depend_id
    this.relation_id = obj.relation_id
  }
}