import RelationItemModel from "../relationItem";

export default class RelationModel  {
  id?: string;
  name: string;
  project_id!: string
  community_id!: string
  items: Array<RelationItemModel>

  constructor(obj: any) {
    this.id = obj.id 
    this.name = obj.name
    this.items = (obj.items ?? []).map((item: any) => new RelationItemModel(item))
    this.project_id = obj.project_id
    this.community_id = obj.community_id
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    obj.id = obj._id
    delete obj._id
    return obj
  }
}