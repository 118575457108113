import { PaginateResponse } from "@/common";
import RelationItemModel from "@/model/relationItem";
import RelationModel from "@/model/tool07_relation";
import RelationServices from "@/services/tool07_relation"
import { Service } from 'typedi';

@Service()
export class RelationSingleClass {
  data?: RelationModel;
  firstItem?: RelationItemModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new RelationServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new RelationServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new RelationServices().create(this.data!, this.firstItem!)
    this.loading = false
  }
  async init(project_id:string, community_id:string) {
    this.loading = true
    this.data = new RelationModel({project_id,community_id})
    this.firstItem = new RelationItemModel({})
    this.loading = false
  }
}

@Service()
export class RelationListClass {
  data?: PaginateResponse<RelationModel>;
  loading: boolean = true;
  query:any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new RelationServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new RelationServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id:string) {
    this.loading = true
    let response = await new RelationServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}