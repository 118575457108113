import MindMapModel from "@/model/mindmap";
import MindMapItemModel from "@/model/mindmapItem";
import { MindMapItemServices, MindMapServices } from "@/services/tool07_mindmap";
import { Service } from 'typedi';
import { v4 as uuidv4 } from "uuid";


@Service()
export class ActiveMindMapClass {
  data?: MindMapModel;
  loading: boolean = false;
  maxTrial = 2
  svg: any
  activeItem?: MindMapItemModel
  itemQuery: any = {}

  async get(id: string) {
    this.loading = true
    this.data = await new MindMapServices().get(id)
    this.loading = false
  }
  constructor(obj: any) {

    this.generateDummyData()
  }
  async paginateItems(query: any) {
    this.itemQuery = query
    if (this.data === undefined) {
      this.data = new MindMapModel({})
    }
    let paginate = await new MindMapItemServices().paginate(query)
    this.data!.items = (await paginate).docs
  }

  async refreshItems() {
    return new Promise(async (resolve, reject) => {
      if (this.data === undefined) {
        this.data = new MindMapModel({})
      }
      let paginate = await new MindMapItemServices().paginate(this.itemQuery)
      this.data!.items = (await paginate).docs
      console.log("refresh items done!", this.data!.items.length)
      resolve("done")
    })
  }

  updateChild(newItem: any) {
    console.log("updating..")
    return new Promise(async (resolve, reject) => {
      let found = this.data!.items.find((item) => item.id === newItem.id)!;
      found.name = newItem.name
      found.description = newItem.description
      let result = await new MindMapItemServices().update(newItem.id, newItem)
      resolve("done")
    })

  }
  removeChild(id: string) {
    return new Promise(async (resolve, reject) => {
      // let foundIndex = this.data!.items.findIndex((item) => item.id === id);
      let result = await new MindMapItemServices().delete(id)
      // this.data?.items.splice(foundIndex, 1)
      await this.refreshItems()
      resolve("done")
    })
  }
  async addChild(depend: string) {
    return new Promise(async (resolve, reject) => {
      let newItem = new MindMapItemModel({ name: "รายการใหม่", depend_id: depend, mindMap_id: this.data!.id },)
      let result = await new MindMapItemServices().create(newItem)
      await this.refreshItems()
      resolve("done")
    })
    // this.data!.items.push(newItem)
  }
  generateDummyData() {
    if (this.data === undefined) {
      this.data = new MindMapModel({})
      console.log("data1 ", this.data)
      let arr: Array<any> = [
        // { name: "เครื่องมือที่ใช้ในชุมชน", id: "00-01", imageUrl:"", description:"" },
        // { name: "การเกษตร", id: "01-01", depend_id: "00-01", imageUrl:"", description:"" },
        // { name: "จอบ", id: "02-01", depend_id: "01-01", imageUrl:"", description:"" },
        // { name: "เสียม", id: "03-01", depend_id: "01-01", imageUrl:"", description:"" },
        // { name: "พลั่ว", id: "04-01", depend_id: "01-01", imageUrl:"", description:"" },
        // { name: "พลั่วเล็ก", id: "05-01", depend_id: "04-01", imageUrl:"", description:"" },
        // { name: "พลั่วใหญ่", id: "06-01", depend_id: "04-01", imageUrl:"", description:"" },
      ]
      this.data!.items = arr.map(item => new MindMapItemModel(item))
    } else {
      console.log("data2 ", this.data)
    }
  }
  clearIsActive() {
    this.data?.items.forEach((item => {
      item.isActive = false
    }))
  }
  get tree() {
    let count = 0
    var nodes: Array<NodeModel> = [];
    var createNode = (elm: MindMapItemModel): NodeModel => {
      var node = {
        id: elm.id,
        name: elm.name,
        depend_id: elm.depend_id,
        children: [],
      };
      return node;
    };
    var fillElements = (node: NodeModel) => {
      node.children = this.data!.items.filter(item => item.depend_id === node.id).map(item => createNode(item))
    };
    var fillTree = (node: NodeModel) => {
      fillElements(node)
      if (node.children.length > 0) {
        node.children.forEach((n: NodeModel) => {
          fillElements(n)
          fillTree(n);
        });
      }
    };
    this.data!.items.forEach((elm, i) => {
      if (!elm.depend_id) {
        var root = createNode(elm);
        fillTree(root);
        nodes.push(root);
      }
    });
    return nodes;
  }
  get dataWithElapsed() {
    let data = this.data?.items.map(el => {
      return {
        ...el,
        elapsed: this.calculateTimeElapsed(el.id)
      }
    })
    data?.sort((a, b) => a.elapsed - b.elapsed)
    return data
  }
  calculateTimeElapsed(id: string) {
    let elapsed = 0
    try {
      let found = this.mapResult.find(item => item.original.search(id) != -1)
      let originalRoute = found?.originalRoute
      let route = found?.route
      let foundIndex = route!.findIndex(item => item === id)
      for (var i = 0; i < foundIndex; i++) {
        elapsed += parseInt(originalRoute![i].split("_")[1])
      }
    } catch (error) {

    }
    return elapsed
  }

  get mapResult() {
    let result = this.pathData.map((rs) => {
      let split = rs.split(",");
      let totalDay = 0;
      let path = "";
      let route: Array<string> = [];
      let originalRoute: Array<string> = [];
      try {
        totalDay = split
          .map((sp: string) => parseInt(sp.split("_")[1]))
          .reduce((a: number, b: number) => a + b, 0);
      } catch (error) { }
      try {
        path = split.map((sp: string) => sp.split("_")[0]).join("=>");
      } catch (error) { }
      try {
        route = split.map((sp: string) => sp.split("_")[0]);
        originalRoute = split.map((sp: string) => sp);
      } catch (error) { }
      return {
        original: rs,
        route,
        originalRoute,
        path,
        totalDay,
        end: route[route.length - 1],
        isMax: false,
      };
    });
    let max = Math.max(...result.map((rs) => rs.totalDay));
    result.forEach((rs) => {
      if (rs.totalDay === max) rs.isMax = true;
    });
    return result;
  }
  get pathData() {
    let result: Array<any> = [];
    let buildStrings = (parent: string, c: string) => {
      return this.data!.items.reduce((r: Array<any>, e: any) => {
        if (e.depend_id === parent) {
          var children = buildStrings(
            e.id,
            c + e.id + "_" + (e.duration ?? 0) + ","
          );
          if (!children.length) result.push(c + e.id + "_" + e.duration);
          r.push(e);
        }
        return r;
      }, []);
    }
    buildStrings("01-01", "");
    return result
  }
}


class NodeModel {
  id: string
  name: string
  children: Array<NodeModel>
  depend_id?: string
  constructor(obj: any) {
    this.id = obj.id
    this.name = obj.name
    this.children = obj.children
    this.depend_id = obj.depend_id
  }
}