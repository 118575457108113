import { BaseModelClass } from "@/model/common"
import { DateTime } from "luxon";

export class MediaModel extends BaseModelClass {
  id!: string
  name!: string
  paths?: Array<any>
  description!: string
  path!: string
  tag!: string
  selectedDate!: Date
  tools: Array<any>;
  user!: string
  category!: string
  community_id!: string
  project_id!: string
  size: number
  latitude: number
  longitude: number
  createdIP!: string
  createdAt!: Date
  modifiedAt!: Date
  constructor(doc: any) {
    super();
    this.id = doc.id
    this.name = doc.name
    this.description = doc.description
    this.tag = doc.tag
    this.selectedDate = doc.selectedDate
    
    this.tools = doc.tools ?? []
    this.paths = doc.paths ?? []
    this.user = doc.user
    this.path = doc.path
    this.size = doc.size
    this.id = doc.id
    this.category = doc.category
    this.community_id = doc.community_id
    this.project_id = doc.project_id
    this.latitude = doc.latitude
    this.longitude = doc.longitude
    this.createdIP = doc.createdIP
    this.createdAt = doc.createdAt
    this.modifiedAt = doc.modifiedAt
  }
  toJSON() {
    let obj:any = Object.assign({}, this)
    // delete obj.paths
    return obj
  }
}
export class ToolsItemModel {
  id?: string;
  name?: string;

  constructor(jsonData: any) {
    this.id = jsonData.id;
    this.name = jsonData.name ?? "";
  }
  toJSON() {
    let obj = Object.assign({}, this) as any
    obj.id = obj.id
    return obj
  }
}