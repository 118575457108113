import { PaginateResponse } from "@/common";
import StructureModel, { StructureItemModel,StructureItemRelationModel } from "@/model/tool07_structure";
import StructureServices, { StructureItemServices,StructureItemRelationServices } from "@/services/tool07_structure"
import { Service } from 'typedi';

@Service()
export class StructureSingleClass {
  data?: StructureModel;
  items?: Array<StructureItemModel>
  item?: StructureItemModel;
  loading: boolean = true;
  async get(id: string) {
    this.loading = true
    this.data = await new StructureServices().get(id)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new StructureServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new StructureServices().create(this.data!)
    this.loading = false
  }
  public init(project_id: string, community_id: string) {
    this.loading = true
    this.data = new StructureModel({ project_id: project_id, community_id: community_id })
    this.loading = false
  }
  

}

@Service()
export class StructureListClass {
  data?: PaginateResponse<StructureModel>;
  loading: boolean = true;
  query: any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new StructureServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new StructureServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id: string) {
    this.loading = true
    let response = await new StructureServices().delete(id)
    this.refreshList()
    this.loading = false
  }

}


@Service()
export class StructureItemSingleClass {
  data?: StructureItemModel;
  loading: boolean = true;

  async get(id: string) {
    this.loading = true
    this.data = await new StructureItemServices().get(id)
    this.loading = false
  }
  public init(project_id: string, community_id: string, structure_id: string) {
    this.loading = true
    this.data = new StructureItemModel({ project_id, community_id, structure_id })
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new StructureItemServices().create(this.data!)
    this.loading = false
  }
}
@Service()
export class StructureItemListClass {
  data?: PaginateResponse<StructureItemModel>;
  loading: boolean = true;
  query: any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new StructureItemServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new StructureItemServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id: string) {
    this.loading = true
    let response = await new StructureItemServices().delete(id)
    this.refreshList()
    this.loading = false
  }
}


@Service()
export class StructureItemRelationSingleClass {
  data?: StructureItemRelationModel;
  loading: boolean = true;

  async get(id: string) {
    this.loading = true
    this.data = await new StructureItemRelationServices().get(id)
    this.loading = false
  }
  async delete(id: string) {
    this.loading = true
    this.data = await new StructureItemRelationServices().delete(id)
    this.loading = false
  }
  public init(project_id: string, community_id: string, structure_id: string) {
    this.loading = true
    this.data = new StructureItemRelationModel({ project_id, community_id, structure_id })
    this.loading = false
  }
  async create() {
    this.loading = true
    this.data = await new StructureItemRelationServices().create(this.data!)
    this.loading = false
  }
  async update() {
    this.loading = true
    this.data = await new StructureItemRelationServices().update(this.data!.id!, this.data!)
    this.loading = false
  }
}
@Service()
export class StructureItemRelationListClass {
  data?: PaginateResponse<StructureItemRelationModel>;
  loading: boolean = true;
  query: any = {}
  async paginate(query: any) {
    this.loading = true
    this.data = await new StructureItemRelationServices().paginate(query)
    this.loading = false
  }
  async refreshList() {
    this.loading = true
    this.data = await new StructureItemRelationServices().paginate(this.query)
    this.loading = false
  }
  async deleteFromList(id: string) {
    this.loading = true
    let response = await new StructureItemRelationServices().delete(id)
    this.refreshList()
    this.loading = false
  }
  async updateFromList(id: string,type:string,level:string ) {
    let doc:any ={
      type:type,
      level:level
    }
    this.loading = true
    let response = await new StructureItemRelationServices().update(id,doc)
    this.refreshList()
    this.loading = false
  }
}
