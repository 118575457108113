export default class RelationItemModel {
  id: string;
  firstName: string;
  lastName: string;
  nickName: string;
  gender: string;
  age: number;
  marriage: string;
  description: string;
  parent: string;
  parentType: string;
  status: string;
  depend_id?: string;
  partner_id?: string;
  relation_id?: string;
  isLeader: boolean;
  isActive = false;
  constructor(obj: any) {
    this.id = obj.id
    this.firstName = obj.firstName
    this.lastName = obj.lastName
    this.nickName = obj.nickName
    this.gender = obj.gender ?? "nonBinary"
    this.age = obj.age ?? 5
    this.marriage = obj.marriage
    this.description = obj.description
    this.parent = obj.parent
    this.parentType = obj.parentType
    this.depend_id = obj.depend_id
    this.partner_id = obj.partner_id
    this.relation_id = obj.relation_id
    this.status = obj.status
    this.isLeader = obj.isLeader ?? false
  }
  toggleActive(){
    this.isActive = !this.isActive
  }
  toJSON() {
    let obj: any = Object.assign({}, this)
    delete obj.isLeader
    delete obj.id
    return obj
  }
}