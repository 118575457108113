import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import PortalView from '../views/PortalView.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import MediaMain from '../views/media/Main.vue'
import MediaForm from '../views/media/Form.vue'

import ProjectMain from '../views/project/Main.vue'
import ProjectForm from '../views/project/Form.vue'
import ProjectDetail from '../views/project/Detail.vue'
import ProjectList from '../views/project/List.vue'
import CommunityMain from '../views/community/Main.vue'
import CommunityForm from '../views/community/Form.vue'
import CommunityDetail from '../views/community/Detail.vue'
import CommunityList from '../views/community/List.vue'

import PersonHistoryGraph from "@/views/tools07/PersonHistoryGraph.vue"
import CommunityHistoryGraph from "@/views/tools07/CommunityHistoryGraph.vue"
import KnowledgeGraph from "@/views/tools07/KnowledgeGraph.vue"
// import StructureGraph from "@/views/tools07/StructureGraph.vue"
import RelationGraph from "@/views/tools07/RelationGraph.vue"
import CalendarGraph from "@/views/tools07/CalendarGraph.vue"
import MindMapGraph from "@/views/tools07/MindMapGraph.vue"

import CalendarList from '../views/tools07/calendar/CalendarListView.vue'
import CalendarForm from '../views/tools07/calendar/CalendarFormView.vue'
import CommunityHistoryForm from '../views/tools07/communityHistory/CommunityHistoryFormView.vue'
import CommunityHistoryList from '../views/tools07/communityHistory/CommunityHistoryListView.vue'
import PersonHistoryMain from '../views/tools07/personHistory/PersonHistoryMainView.vue'
import PersonHistoryList from '../views/tools07/personHistory/PersonHistoryListView.vue'
import PersonHistoryForm from '../views/tools07/personHistory/PersonHistoryFormView.vue'
import PersonHistoryDetail from '../views/tools07/personHistory/PersonHistoryDetailView.vue'
import PersonHistoryItemForm from '../views/tools07/personHistory/PersonHistoryItemFormView.vue'
import KnowledgeForm from '../views/tools07/knowledge/KnowledgeFormView.vue'
import KnowledgeList from '../views/tools07/knowledge/KnowledgeListView.vue'
import StructureList from '../views/tools07/structure/StructureListView.vue'
import StructureForm from '../views/tools07/structure/StructureFormView.vue'
import StructureDetail from '../views/tools07/structure/StructureDetailView.vue'
import StructureItemForm from '../views/tools07/structure/StructureItemFormView.vue'
import RelationMain from '../views/tools07/relation/RelationMainView.vue'
import RelationList from '../views/tools07/relation/RelationListView.vue'
import RelationForm from '../views/tools07/relation/RelationFormView.vue'
import WalkingMapForm from '../views/tools07/walkingMap/WalkingMapFormView.vue'

// V2
import ResourceGraph from "@/views/graph/ResourceGraph.vue"
import StructureGraph from "@/views/graph/StructureGraph.vue"
import FamilyGraph from "@/views/graph/FamilyGraph.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: '/graph/resource/:id',
    name: 'Resource Graph New',
    component: ResourceGraph
  },
  {
    path: '/graph/structure/:id',
    name: 'Structure Graph New',
    component: StructureGraph
  },
  {
    path: '/graph/family/:id',
    name: 'Family Graph New',
    component: FamilyGraph
  },
  {
    path: '/tool07/personhistory/graph',
    name: 'Person History Graph',
    component: PersonHistoryGraph
  },
  {
    path: '/tool07/personhistory/graph/:id',
    name: 'Person History Graph With ID',
    component: PersonHistoryGraph
  },
  {
    path: '/tool07/communityhistory/graph/:project_id/:community_id',
    name: 'Community History Graph',
    component: CommunityHistoryGraph
  },
  {
    path: '/tool07/knowledge/graph',
    name: 'Knowledge Graph',
    component: KnowledgeGraph
  },
  {
    path: '/tool07/knowledge/graph/:id',
    name: 'Knowledge Graph With ID',
    component: KnowledgeGraph
  },
  {
    path: '/tool07/structure/graph/:id',
    name: 'Structure Graph',
    component: StructureGraph
  },
  {
    path: '/tool07/relation/graph',
    name: 'Relation Graph',
    component: RelationGraph
  },
  {
    path: '/tool07/relation/graph/:id',
    name: 'Relation Graph With ID',
    component: RelationGraph
  },

  {
    path: '/tool07/mindmap/graph',
    name: 'MindMap Graph',
    component: MindMapGraph
  },
  {
    path: '/tool07/mindmap/graph/:id',
    name: 'MindMap Graph With ID',
    component: MindMapGraph
  },
  {
    path: '/tool07/calendar/graph/:id',
    name: 'Calendar Graph',
    component: CalendarGraph
  },
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/privacy',
    name: 'privacy policy',
    component: PrivacyPolicy
  },
  {
    path: '/portal',
    name: 'portal',
    component: PortalView,
    children: [
      {
        path: "project",
        name: "Project Main",
        component: ProjectMain,
        children: [
          {
            path: 'create',
            name: 'ProjectForm',
            component: ProjectForm
          },
          {
            path: 'edit/:id',
            name: 'ProjectEdit',
            component: ProjectForm
          },
          {
            path: 'detail/:id',
            name: 'ProjectDetail',
            component: ProjectDetail
          },
          {
            path: '',
            name: 'ProjectList',
            component: ProjectList
          },
          {
            path: 'communities/:id',
            name: 'ProjectDetail',
            component: ProjectDetail
          },
        ]
      },
      {
        path: "community",
        name: "Community Main",
        component: CommunityMain,
        children: [
          {
            path: 'create/:project_id',
            name: 'CommunityForm',
            component: CommunityForm
          },
          {
            path: 'edit/:id',
            name: 'CommunityEdit',
            component: CommunityForm
          },
          {
            path: 'detail/:project_id/:id',
            name: 'CommunityDetail',
            component: CommunityDetail
          },
          {
            path: '',
            name: 'CommunityList',
            component: CommunityList
          },
          {
            path: 'tools/relation/:project_id/:community_id/',
            name: 'Relation List',
            component: RelationList
          },
          {
            path: 'tools/relation/create/:project_id/:community_id/',
            name: 'Relation Create',
            component: RelationForm
          },
          {
            path: 'tools/calendar/:project_id/:community_id/',
            name: 'Calendar List',
            component: CalendarList
          },
          {
            path: 'tools/calendar/create/:project_id/:community_id/',
            name: 'Calendar Create',
            component: CalendarForm
          },
          {
            path: 'tools/calendar/edit/:id',
            name: 'Calendar Edit',
            component: CalendarForm
          },
          {
            path: 'tools/communityhistory/list/:project_id/:community_id/',
            name: 'CommunityHistory List',
            component: CommunityHistoryList
          },
          {
            path: 'tools/communityhistory/create/:project_id/:community_id/',
            name: 'CommunityHistory Create',
            component: CommunityHistoryForm
          },
          {
            path: 'tools/communityhistory/edit/:id',
            name: 'CommunityHistory Edit',
            component: CommunityHistoryForm
          },
          {
            path: 'tools/personhistory/:project_id/:community_id/',
            name: 'PersonHistory List',
            component: PersonHistoryList
          },
          {
            path: 'tools/personhistory/create/:project_id/:community_id/',
            name: 'PersonHistoryForm Create',
            component: PersonHistoryForm
          },
          {
            path: 'tools/personhistory/edit/:id',
            name: 'PersonHistoryForm Form',
            component: PersonHistoryForm
          },
          {
            path: 'tools/personhistory/detail/:id',
            name: 'PersonHistoryDetail',
            component: PersonHistoryDetail
          },
          {
            path: 'tools/personhistory/create/item/:person_history_id',
            name: 'PersonHistoryItemForm Create',
            component: PersonHistoryItemForm
          },
          {
            path: 'tools/personhistory/edit/item/:id',
            name: 'PersonHistoryItem Form',
            component: PersonHistoryItemForm
          },
          {
            path: 'tools/mindmap/create/:project_id/:community_id/',
            name: 'KnowledgeForm Create',
            component: KnowledgeForm
          },
          {
            path: 'tools/mindmap/edit/:id',
            name: 'knowledgeForm Form',
            component: KnowledgeForm
          },
          {
            path: 'tools/mindmap/list/:project_id/:community_id/',
            name: 'Knowledge List',
            component: KnowledgeList
          },
          {
            path: 'tools/walkingMap/create/:project_id/:community_id/',
            name: 'WalkingMap Create',
            component: WalkingMapForm
          },
          {
            path: 'tools/walkingMap/edit/:id',
            name: 'WalkingMapForm Form',
            component: WalkingMapForm
          },
          {
            path: 'tools/structure/list/:project_id/:community_id/',
            name: 'StructureList List',
            component: StructureList
          },
          {
            path: 'tools/structure/create/:project_id/:community_id/',
            name: 'StructureForm Create',
            component: StructureForm
          },
          {
            path: 'tools/structure/create/item/:structure_id',
            name: 'StructureItemForm Create',
            component: StructureItemForm
          },
          {
            path: 'tools/structure/edit/item/:id',
            name: 'StructureItemForm Edit',
            component: StructureItemForm
          },
          {
            path: 'tools/structure/detail/:id',
            name: 'Structure Detail',
            component: StructureDetail
          },
          {
            path: "collection",
            name: "MediaMain",
            component: MediaMain,
            children: [
              {
                path: 'create/:project_id/:community_id/',
                name: 'MediaForm',
                component: MediaForm
              },
              {
                path: 'edit/:id',
                name: 'MediaEdit',
                component: MediaForm
              },
            ]
          },
        ]
      },

    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
