import ADDRESS from "@/settings";
import axios from "axios"
import KnowledgeModel from "@/model/tool07_knowledge";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class KnowledgeServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<KnowledgeModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/resources/paginate`, query);
      let docs: Array<KnowledgeModel> = response.data.docs.map((el: any) => new KnowledgeModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<KnowledgeModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<KnowledgeModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/resource/get/${id}`);
      let doc: KnowledgeModel = new KnowledgeModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, Knowledge: KnowledgeModel) {
    return new Promise<KnowledgeModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/resource/get/${id}`, Knowledge );
      let doc: KnowledgeModel = new KnowledgeModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(Knowledge: KnowledgeModel) {
    return new Promise<KnowledgeModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/resource/create/`, Knowledge);
      let doc: KnowledgeModel = new KnowledgeModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<KnowledgeModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/resource/delete/${id}`);
      let doc: KnowledgeModel = new KnowledgeModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
