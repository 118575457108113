import ADDRESS from "@/settings";
import axios from "axios"
import PersonHistoryModel, { PersonHistoryItemModel } from "@/model/tool07_personHistory";
import { PaginateResponse } from "@/common"
import { useToast, POSITION } from "vue-toastification";


export default class PersonHistoryServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<PersonHistoryModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/persons/paginate`, query);
      console.log(response)
      let docs: Array<PersonHistoryModel> = response.data.docs.map((el: any) => new PersonHistoryModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<PersonHistoryModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<PersonHistoryModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/person/get/${id}`);
      let doc: PersonHistoryModel = new PersonHistoryModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, PersonHistory: PersonHistoryModel) {
    return new Promise<PersonHistoryModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/person/get/${id}`, PersonHistory );
      let doc: PersonHistoryModel = new PersonHistoryModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(PersonHistory: PersonHistoryModel) {
    return new Promise<PersonHistoryModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/person/create/`, PersonHistory);
      let doc: PersonHistoryModel = new PersonHistoryModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<PersonHistoryModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/person/delete/${id}`);
      let doc: PersonHistoryModel = new PersonHistoryModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}


export class PersonHistoryItemServices {
  public paginate(query: any) {
    return new Promise<PaginateResponse<PersonHistoryItemModel>>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/persons-event/paginate`, query);
      let docs: Array<PersonHistoryItemModel> = response.data.docs.map((el: any) => new PersonHistoryItemModel(el))
      let pages = response.data.pages
      let total = response.data.total
      let paginateResponse = new PaginateResponse<PersonHistoryItemModel>(docs, pages, total)
      resolve(paginateResponse)
    })
  }

  public get(id: string) {
    return new Promise<PersonHistoryItemModel>(async (resolve, reject) => {
      let response = await axios.get(`${ADDRESS}/person/get-events/${id}`);
      let doc: PersonHistoryItemModel = new PersonHistoryItemModel(response.data.doc)
      resolve(doc)
    })
  }

  public update(id: string, PersonHistory: PersonHistoryItemModel) {
    return new Promise<PersonHistoryItemModel>(async (resolve, reject) => {
      let response = await axios.patch(`${ADDRESS}/person/update-event/${id}`, PersonHistory );
      let doc: PersonHistoryItemModel = new PersonHistoryItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U1)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public create(PersonHistoryItem: PersonHistoryItemModel) {
    return new Promise<PersonHistoryItemModel>(async (resolve, reject) => {
      let response = await axios.post(`${ADDRESS}/personhistoryitem/create/`, PersonHistoryItem);
      let doc: PersonHistoryItemModel = new PersonHistoryItemModel(response.data.doc)
      const toast = useToast();
      toast.success("บันทึกข้อมูลแล้ว (ref:S-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }

  public delete(id: string) {
    return new Promise<PersonHistoryItemModel>(async (resolve, reject) => {
      let response = await axios.delete(`${ADDRESS}/personhistoryitem/delete/${id}`);
      let doc: PersonHistoryItemModel = new PersonHistoryItemModel(response.data.doc)
      const toast = useToast();
      toast.warning("ลบข้อมูลแล้ว (ref:D-U2)", {
        timeout: 2000,
        position: POSITION.BOTTOM_RIGHT,
      });
      resolve(doc)
    })
  }
}
